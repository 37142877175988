import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import UserOverview from "./Pages/UserOverview";
import ServiceVerificationDetails from "./Pages/ServiceVerificationDetails";
import ServiceProviders from "./Pages/ServiceProviders";
import { ProfileProvider } from "./Context/ProfileContext";
import ProtectedRoutes from "./Components/ProtectedRoute";
import Report from "./Pages/Report";
import ReportView from "./Pages/ReportView";
import Support from "./Pages/Support";
import ChatSupport from "./Pages/ChatSupport";
import Notification from "./Pages/Notification";
import CreateNotification from "./Pages/CreateNotificaion";
import ServiceCategory from "./Pages/ServiceCategory";
import AddCategories from "./Pages/AddCategories";
import EditService from "./Pages/EditService";
import AssistsProTermsAndConditions from "./Pages/AssistsProTermsAndConditions";
import AssistsProPrivacyPolicy from "./Pages/AssistsProPrivacyPolicy";
import AssistsTermsAndConditions from "./Pages/AssistTermsAndConditions";
import AboutUs from "./Pages/AboutUs";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ProfileProvider>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route
              path="/reset-password/:token"
              element={<ResetPassword />}
            ></Route>
            <Route
              path="/otp-varification"
              element={<OtpVarification />}
            ></Route>

            <Route path="/about-us" element={<AboutUs />}></Route>

            <Route
              path="/assists-pro-terms-and-conditions"
              element={<AssistsProTermsAndConditions />}
            ></Route>

            <Route
              path="/assists-pro-privacy-policy"
              element={<AssistsProPrivacyPolicy />}
            ></Route>

            <Route
              path="/assists-terms-and-conditions"
              element={<AssistsTermsAndConditions />}
            ></Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/error" element={<Error />}></Route>
              <Route
                path="/under-maintenance"
                element={<UnderMaintenance />}
              ></Route>
              <Route path="/user" element={<User />}></Route>
              <Route path="/my-profile" element={<MyProfile />}></Route>
              <Route
                path="/user-overview/:id"
                element={<UserOverview />}
              ></Route>
              <Route
                path="/service-verification-details/:id"
                element={<ServiceVerificationDetails />}
              ></Route>
              <Route
                path="/service-providers"
                element={<ServiceProviders />}
              ></Route>
              <Route
                path="/user-verification"
                element={<UserVerification />}
              ></Route>
              <Route path="/report" element={<Report />} />
              <Route path="/report-view/:id" element={<ReportView />} />
              <Route path="/support" element={<Support />} />
              <Route path="/chat-support" element={<ChatSupport />} />
              <Route path="/notification" element={<Notification />} />
              <Route
                path="/create-notification"
                element={<CreateNotification />}
              />

              <Route path="/service-category" element={<ServiceCategory />} />
              <Route path="/add-categories" element={<AddCategories />} />
              <Route path="/edit-categories/:id" element={<EditService />} />
            </Route>
          </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
