import React from "react";
import { Container } from "react-bootstrap";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <div className="about-us-page">
          <h1>About Us</h1>
          <div className="about-us-disp">
            <p>
              The <b>Roadside Assist App </b> is a cutting-edge platform
              designed to deliver fast, reliable, and convenient roadside
              assistance for both individuals and businesses. With a
              user-friendly interface, the app allows guests and members to
              access services like towing, on-site mechanical repairs, battery
              jump-starts, tire replacement, fuel delivery, and lockout
              assistance. Guests can request help without creating an account,
              while members enjoy added benefits like profile management and
              service history. The app offers real-time GPS tracking, secure
              payment options, and a referral program, making it easy for users
              to track service providers, manage costs, and earn rewards.
              Features like an SOS button for emergencies, AI-based service
              predictions, and offline functionality ensure reliability in any
              situation, supported by 24/7 customer support for seamless
              assistance
            </p>

            <p>
              For service providers, the companion <b>Roadside Assist App </b>
              simplifies operations with detailed profile management, real-time
              service notifications, and navigation tools for efficient routing.
              Providers can track earnings through an integrated dashboard and
              communicate directly with customers via in-app messaging. Both
              apps are built with robust security features like two-factor
              authentication (2FA) and data encryption, ensuring user safety and
              trust. Designed for scalability and powered by cloud integration,
              the platform guarantees smooth performance across locations,
              revolutionizing how drivers and service providers connect during
              roadside emergencies.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
